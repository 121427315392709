import { LOG_LEVEL, storage } from '@b2x/core/bundle';
import type { ActionFlow, ImportMap, LoginData } from './types';
import { getImportMap } from './utilities/helpers';
import { logger } from './utilities/logger';

class Action {
  actionName: string;
  importPrefix = '@b2x/loginaction/';
  importMap: ImportMap;
  loginData: LoginData;
  isVersionChanged = false;
  cacheKey = 'b2x.lal_cache';
  lalCache = storage.local.get(this.cacheKey, {});
  importMapActionUrl: string;

  constructor(actionName: string, loginData: LoginData, importMap: ImportMap) {
    this.actionName = actionName;
    this.importMap = importMap;
    this.loginData = loginData;
    this.importMapActionUrl = this.importMap?.imports[`${this.importPrefix}${this.actionName}`] as string;

    this.isVersionChanged = this.importMapActionUrl !== this.getCachedActionUrl();
  }

  async runCondition() {
    const parts = this.importMapActionUrl.split('/');
    parts[parts.length - 1] = 'condition.js';

    const conditionScriptUrl = parts.join('/');

    const shouldRun = await (await import(/* @vite-ignore */ conditionScriptUrl)).default(this.loginData);

    logger(`Checking condition for action: ${this.actionName}`, {
      args: [shouldRun ? '🚧' : '❌'],
      group: 'CONDITION',
      level: LOG_LEVEL.DEFAULT,
    });

    return shouldRun;
  }

  async runAction() {
    const actionFn = await import(/* @vite-ignore */ this.importMapActionUrl);

    await actionFn.default(this.loginData);

    logger(` ✅ Executed action: ${this.actionName}`, {
      args: [],
      group: 'ACTION',
      level: LOG_LEVEL.INFO,
    });
  }

  async run() {
    logger(`🚀 Running action: ${this.actionName}`, {
      args: [],
      group: 'FLOW',
      level: LOG_LEVEL.INFO,
    });

    try {
      if (await this.runCondition()) {
        await this.runAction();
      }
    } catch (error) {
      logger(`⛔️ Error running condition or action: ${this.actionName}`, {
        args: [error],
        group: 'FLOW',
        level: LOG_LEVEL.WARN,
      });
    }
  }

  cacheAction() {
    this.lalCache[this.actionName] = this.importMapActionUrl;
    storage.local.set(this.cacheKey, this.lalCache, true);
  }

  getCachedActionUrl() {
    return this.lalCache[this.actionName];
  }
}

export async function runMarketFlow(marketFlow: ActionFlow, loginData: LoginData) {
  const importMap = getImportMap();

  for (const action in marketFlow) {
    await new Action(marketFlow[action] as string, loginData, importMap).run();
  }
}
