import { CONNECTIONS, LOG_LEVEL } from '@b2x/core/bundle';

import { type AuthenticationState, isLoggedIn, isPending } from '@b2x/authentication-library';
import type { ImportMap, LoginData } from '../types';

import { initializeStore } from '@seamless/store';
import { logger } from './logger';

export function buildLoginDataObject(authState: AuthenticationState, country: string): LoginData {
  return {
    authState: {
      jwe: authState.jwe,
      identityProvider: authState.identityProvider,
    },
    currentPage: window.location.href,
    country: country,
  };
}

export const getImportMap = (): ImportMap => {
  const script = document.querySelector('script[type="importmap"]');
  if (script?.textContent) {
    try {
      return JSON.parse(script.textContent);
    } catch {
      logger('Error parsing import map', {
        args: [],
        group: 'HELPERS',
        level: LOG_LEVEL.ERROR,
      });
    }
  }
  return {
    imports: {},
  };
};

export async function getAuthenticationState() {
  return await new Promise<AuthenticationState | null>((resolve, reject) => {
    initializeStore().subscribe(CONNECTIONS.AUTH_CONNECTION_NAME, (state: AuthenticationState) => {
      if (isPending(state)) {
        return;
      }

      if (!isLoggedIn(state)) {
        resolve(null);
      }

      resolve(state);
    });
  });
}
