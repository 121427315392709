import { LOG_LEVEL, storage } from '@b2x/core/bundle';
import type { ActionFlow } from './types';
import { logger } from './utilities/logger';

const standardFlow: ActionFlow = [
  'declarationOfConsent',
  'checkForConsentUpdates',

  // this ones should not be actions
  'loadAndPersistVehicleDataWko',
  'loadAndPersistDealersDataWko',
];

const natconFlow: ActionFlow = [
  'checkNationalConsentStatus',
  'checkForConsentUpdates',

  // this ones should not be actions
  'loadAndPersistVehicleDataWko',
  'loadAndPersistDealersDataWko',
];

const marketFlows: Record<string, null | ActionFlow> = {
  ba: null,
  rs: null,
  hk: natconFlow,
  jp: natconFlow,
  kr: natconFlow,
  la: natconFlow,
  mo: natconFlow,
  my: natconFlow,
  sg: natconFlow,
  vn: natconFlow,
  standard: standardFlow,
};

export function getMarketFlow(market: string) {
  const customFlow = storage.local.get('b2x.lal_flow');

  if (customFlow) {
    logger('Using custom flow', {
      args: [customFlow],
      group: 'flow',
      level: LOG_LEVEL.WARN,
    });

    return customFlow as ActionFlow;
  }

  return marketFlows[market] || (marketFlows.standard as ActionFlow);
}
